<template>
    <div class="block-contacts">
        <StackRouterHeaderBar />
        <h1 class="title m-b-40">{{ $translate('BLOCK_FRIEND') }}</h1>

        <main class="main">
            <p class="desc m-b-16">
                아는 사람을 만날까봐 걱정되세요?<br />
                <span class="c-primary f-bold">연락처를 등록하면 서로 소개되지 않습니다.</span>
            </p>

            <p class="desc-small m-b-40">
                모든 연락처는 차단 목적으로만 사용되며,<br />
                절대 공개되지 않습니다.
            </p>

            <div class="blocked-contacts">
                <i class="material-icons">person_add_disabled</i>
                <span class="text">차단된 연락처</span>
                <span class="count f-bold">{{ contactsLength }}개</span>
            </div>
        </main>

        <footer class="footer">
            <button class="btn btn-primary" @click="onClickRegistration">{{ $translate('REGISTER_CONTACTS') }}</button>
        </footer>
    </div>
</template>

<script>
import StackRouterHeaderBar from '../../components/app/StackRouterHeaderBar.vue'
import userService from '@/services/user'

export default {
    name: 'BlockContactsPage',
    components: { StackRouterHeaderBar },
    data: () => ({}),
    watch: {
        contacts(newContacts, oldContacts) {
            if (newContacts.length > 0) {
                this.registerContacts(
                    newContacts.map(c => {
                        if ((c.phoneNumbers[0] || {}).number) {
                            return { phone_number: c.phoneNumbers[0].number }
                        }
                    }),
                )
            }
        },
    },
    computed: {
        contacts() {
            return this.$store.getters.contacts || []
        },
        registeredContactsLength() {
            return this.$store.getters.registeredContactsLength || 0
        },
        me() {
            return this.$store.getters.me
        },
        contactsLength() {
            if (this.registeredContactsLength > 0) {
                return this.registeredContactsLength
            }

            return this.contacts.length
        },
    },
    methods: {
        onClickRegistration() {
            const { app_version: version } = this.$store.getters.device

            if (version < '1.2.2') {
                this.$toast.error('앱 업데이트 후 사용할 수 있어요')
                return
            }

            this.$nativeBridge.postMessage({
                action: 'getContactList',
            })
        },
        async registerContacts(contacts) {
            try {
                await userService.registerContacts(this.me.id, { contacts })
                this.$toast.success('주소록에 있는 모든 연락처가 등록되었어요')
                this.$store.dispatch('getRegisteredContactsLength')
            } catch (e) {
                console.error(e)
            }
        },
    },
    mounted() {
        this.$store.dispatch('getRegisteredContactsLength')
    },
}
</script>

<style lang="scss" scoped>
.block-contacts {
    .title,
    .main {
        margin-left: 16px;
    }

    .title {
        font-size: 24px;
        color: black;
        font-weight: 500;

        @include f-medium;
    }

    .desc {
        font-size: 16px;
        color: $grey-08;
        line-height: normal;

        &-small {
            font-size: 14px;
            color: $grey-06;
        }
    }

    .blocked-contacts {
        padding: 10px 16px;
        border-radius: 10px;
        background-color: $grey-02;
        display: inline-flex;
        align-items: center;

        .material-icons {
            font-size: 20px;
            margin-right: 12px;
        }

        .text {
            margin-right: 4px;
            font-size: 15px;
            color: $grey-08;
        }

        .count {
            font-size: 16px;
            color: $purple-primary;
        }
    }

    .footer {
        position: absolute;
        bottom: 0;
        width: 100vw;
        border-top: solid 1px $grey-02;
        padding: 16px;

        .btn {
            width: 100%;
            height: 48px;
        }
    }
}
</style>
